import { Injectable } from '@angular/core';
import { urlFix } from '../../shared/routines';
import { DOCUMENT } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

/*
  Generated class for the WindowrefService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
const chat = environment.chatProvider;

function _window() : any {
  // return the global native browser window object
  return window;
}

function _crisp(): any {
  return window["$crisp"];
}

@Injectable({providedIn: 'root'})
export class WindowrefService {
  renderTimeOut;
  public referral:string;
  public coupon: string;
  public popUpShowing: boolean;

  constructor (
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    // console.log('Hello WindowrefService Provider');
  }
  
  public isBrowserRendering() {
    // console.log("is Browser Rendering", this.platformId, isPlatformBrowser(this.platformId))
    return isPlatformBrowser(this.platformId);
  }

  public isHeadlessBrowser() {
    // console.log("this.nativeWindow", this.nativeWindow.navigator);
    // console.log(`this.nativeWindow.userAgent.toLowerCase().includes("headless")`, this.nativeWindow.navigator.userAgent.toLowerCase().includes("headless"));
    try {
      return this.userAgent().toLowerCase().includes("headless");
    } catch(err) {
      return false;
    }
  }

  public isNucampSSRBrowser() {
    // console.log("this.nativeWindow.userAgent", this.nativeWindow.navigator.userAgent);
    // console.log(`this.nativeWindow.userAgent.toLowerCase().includes("headless")`, this.nativeWindow.navigator.userAgent.toLowerCase().includes("headless"));
    try {
      return this.userAgent().toLowerCase().includes("nucamp/ssr");
    } catch(err) {
      return false;
    }
  }

  public setPopUpShown(isit) {
    this.popUpShowing = isit;
  }

  public repositionZendesk() {
    if (chat === "zendesk") {
      const frameIndex = ["0", "1", "2", "3", "4", "5"].find(index => this.nativeWindow[index]?.frames?.frameElement?.attributes[0]?.nodeValue?.includes('messaging'));
      if (this.is("desktop")) {
        if (frameIndex) this.nativeWindow[frameIndex].frames.frameElement.style.right = "32px";
      }
    }
  }
  
  public deleteSlowNetworkLoader() {
    const loader = this.nativeDocument.getElementsByClassName("app-slownetwork-centered")[0];
    loader.remove();
  }

  public getGeoData(user) {
    const data = {
      street: "",
      city: "",
      region: "",
      country: "",
      zipcode: ""
    }

    if (user && user.fulladdress && Array.isArray(user.fulladdress)) {
      const countryData = user.fulladdress.find((address) => address.types.includes("country"));
      const zipcodeData = user.fulladdress.find((address) => address.types.includes("postal_code"));
      const stateData = user.fulladdress.find((address) => address.types.includes("administrative_area_level_1"));
      const cityData = user.fulladdress.find((address) => address.types.includes("locality"));
      const streetPremiseData = user.fulladdress.find((address) => address.types.includes("premise"));
      const streetSubPremiseData = user.fulladdress.find((address) => address.types.includes("subpremise"));
      const streetNumberData = user.fulladdress.find((address) => address.types.includes("street_number"));
      const streetRouteData = user.fulladdress.find((address) => address.types.includes("route"));
      if (countryData) {
        data.country = countryData.short_name;
      }
      if (zipcodeData) {
        data.zipcode = zipcodeData.long_name;
      }
      if (stateData) {
        data.region = stateData.short_name;
      }
      if (cityData) {
        data.city = cityData.long_name;
      }
      if (streetNumberData) {
        data.street = data.street + " " + streetNumberData.long_name;
      }
      if (streetRouteData) {
        data.street = data.street + " " + streetRouteData.long_name;
      }
      if (streetPremiseData) {
        data.street = data.street + " " + streetPremiseData.long_name;
      }
      if (streetSubPremiseData) {
        data.street = data.street + " " + streetSubPremiseData.long_name;
      }
    }

    return data;
  }
  
  public hideChatWidget(force?) {
    if (this.is("mobile") || force) {
      if (chat === "crisp") {
        if (this.crisp) {
          this.crisp.push(["do", "chat:hide"]);
          // console.log("crisp chat hidden1");
        } else {
          setTimeout(() => { 
            if (this.crisp) this.crisp.push(["do", "chat:hide"]);
            // console.log("crisp chat hidden2");
          }, 5000 );
        }
      }
      else if (chat === "zendesk") setTimeout(() => { this.hideChatWidget(); }, 5000 );
    }
  }

  public showChatWidget(force?) {
    if (this.is("mobile") || force) {
      if (chat === "crisp") {
        if (this.crisp) {
          this.crisp.push(["do", "chat:show"]);
          // console.log("crisp chat show1");
        } else {
          setTimeout(() => { 
            if (this.crisp) this.crisp.push(["do", "chat:show"]); 
            // console.log("crisp chat show2");
          }, 5000 );
        }
      }
      else if (chat === "zendesk") setTimeout(() => { this.showZendesk(); }, 5000 );
    }
  }

  public hideZendesk(force?) {
    // const frameIndex = ["0", "1", "2", "3", "4", "5"].find(index => this.nativeWindow[index]?.frames?.frameElement?.attributes[0]?.nodeValue?.includes('messaging'));
    // if (frameIndex) this.nativeWindow[frameIndex].frames.frameElement.style.display = "none";
    const zenDeskDiv = this.nativeWindow.document.querySelectorAll('[role="presentation"]');
    const zenDeskFrame = this.nativeWindow.document.querySelectorAll('[title="Button to launch messaging window"]');
    // console.log(zenDeskDiv, zenDeskFrame);
    if (zenDeskDiv && zenDeskDiv.length === 1) {
      zenDeskDiv[0].style.display = "none";
    }
    if (zenDeskFrame && zenDeskFrame.length === 1) {
      zenDeskFrame[0].style.display = "none";
    }
  }

  public copyClipboard(text): Promise<any> {
    // console.log(this.nativeWindow.navigator);
    return this.nativeWindow.navigator.clipboard.writeText(text);
  }

  public showZendesk() {
    // const frameIndex = ["0", "1", "2", "3", "4", "5"].find(index => this.nativeWindow[index]?.frames?.frameElement?.attributes[0]?.nodeValue?.includes('messaging'));
    // if (frameIndex) this.nativeWindow[frameIndex].frames.frameElement.style.display = "unset";
    const zenDeskDiv = this.nativeWindow.document.querySelectorAll('[role="presentation"]');
    const zenDeskFrame = this.nativeWindow.document.querySelectorAll('[title="Button to launch messaging window"]');
    if (zenDeskDiv && zenDeskDiv.length === 1) {
      zenDeskDiv[0].style.display = "unset";
    }
    if (zenDeskFrame && zenDeskFrame.length === 1) {
      zenDeskFrame[0].style.display = "unset";
    }
  }

  public is(platform) {
    if (platform.toLowerCase() === 'mobile') return (this.nativeWindow.innerWidth < 768);
    else if (platform.toLowerCase() === 'desktop') return (this.nativeWindow.innerWidth >= 768);
    else if (platform.toLowerCase() === 'sm') return (this.nativeWindow.innerWidth >= 576);
    else if (platform.toLowerCase() === 'md') return (this.nativeWindow.innerWidth >= 768);
    else if (platform.toLowerCase() === 'lg') return (this.nativeWindow.innerWidth >= 992);
    else if (platform.toLowerCase() === 'xl') return (this.nativeWindow.innerWidth >= 1200);
  }

  public isDarkMode() {
    if (this.isBrowserRendering()) {
      return this.nativeWindow.matchMedia('(prefers-color-scheme: dark)').matches;
    } else {
      return false;
    }
  }

  public width() {
    if (this.nativeWindow.innerWidth) {
      return this.nativeWindow.innerWidth;
    } else {
      return 400;
    }
  }

  public height() {
    if (this.nativeWindow.innerHeight) {
      return this.nativeWindow.innerHeight;
    } else {
      return 750;
    }
  }

  public isInView (el){
    let rect = el.getBoundingClientRect();
    const top = rect.top;
    const height = rect.height;
    el = el.parentNode;

    do {
      rect = el.getBoundingClientRect();
      if (top <= rect.bottom === false) return false;
      // Check if the element is out of view due to a container scrolling
      if ((top + height) <= rect.top) return false
      el = el.parentNode;
    } while (el != document.body);
    // Check its within the document viewport
    return top <= document.documentElement.clientHeight;
  }

  public deleteUTN() {
    try {
      if (this.nativeWindow.localStorage) {
      // localStorage supported
      this.nativeWindow.localStorage.removeItem('utn');
    }
  } catch (err) {
    // console.log(err);
  }

  }

  public pushUTN(tag) {
    try {
        if (this.nativeWindow.localStorage) {
        // localStorage supported
        let UTN;
        UTN = this.getUTN();
        if (!UTN) {
          UTN = [];
        }
        UTN.push(tag)
        this.nativeWindow.localStorage.setItem('utn', JSON.stringify(UTN));
      }
    } catch (err) {
      console.log(err);
    }
  }

  public setFirstVisit(){
    if (this.nativeWindow.localStorage){
      try {
        const fv = localStorage.getItem('firstVisit');
        if (!fv) {
          localStorage.setItem('firstVisit', new Date().toISOString())
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  public prerenderReady(ready) {
    this.nativeWindow.prerenderReady = ready
  }

  public disableConsoleLog() {
    if (environment.production && this.isBrowserRendering()) {
      this.nativeWindow.console.log = () => {};
    }
  }

  public getFirstVisit(){
    if (this.nativeWindow.localStorage){
      const fv = localStorage.getItem('firstVisit');
      if (fv) {
        // console.log("firstVisit", fv);
        return fv;
      }
      else return null
    }
    else return null
  }

  public nucampTracking(event: {medium?: string, campaign?: string, content?: string, term?: string, keyword?: string}) {
    const UTNTrack = {
      source: "nucamp",
      medium: event.medium || "",
      campaign: event.campaign || "",
      content: event.content || "",
      term: event.term || "",
      keyword: event.keyword || "",
      timestamp: new Date(),
    }
    this.pushUTN(UTNTrack);
  }

  public utnTracking() {
    const UTNTrack ={
      source: "",
      medium: "",
      campaign: "",
      content: "",
      term: "",
      keyword: "",
      gclid: "",
      msclkid: "",
      timestamp: new Date(),
    }
    const source = this.queryParamMap("utm_source");
    // console.log("utnTracking", source);

    // if the user comes from Google, Facebook or Microsoft Paid
    if (source && source !== '') {
      UTNTrack.source = this.queryParamMap(`utm_source`).toLowerCase();
      UTNTrack.medium = this.queryParamMap(`utm_medium`).toLowerCase();
      UTNTrack.campaign = this.queryParamMap(`utm_campaign`).toLowerCase();
      UTNTrack.content = this.queryParamMap(`utm_content`).toLowerCase();
      UTNTrack.term = this.queryParamMap(`utm_term`).toLowerCase();
      UTNTrack.keyword = this.queryParamMap(`utm_keyword`).toLowerCase();
      UTNTrack.gclid = this.queryParamMap(`gclid`);
      UTNTrack.msclkid = this.queryParamMap(`msclkid`);
      this.pushUTN(UTNTrack);
    }
  }

  public isPaidVisitor() {
    const utn = this.getUTN();
    // console.log("utn", utn);
    if (utn && utn[0] &&
      (
        (utn[0].source === "google" && (utn[0].medium === "paid" || utn[0].medium === "cpc")) || 
        (utn[utn.length - 1].source === "google" && (utn[utn.length - 1].medium === "paid" || utn[utn.length - 1].medium === "cpc")) || 
        (utn[0].source === "reddit" && (utn[0].medium === "paid" || utn[0].medium === "cpc")) ||
        (utn[utn.length - 1].source === "reddit" && (utn[utn.length - 1].medium === "paid" || utn[utn.length - 1].medium === "cpc")) ||
        (utn[0].source === "microsoft" && (utn[0].medium === "paid" || utn[0].medium === "cpc")) ||
        (utn[utn.length - 1].source === "microsoft" && (utn[utn.length - 1].medium === "paid" || utn[utn.length - 1].medium === "cpc")) ||
        (utn[0].source === "facebook" && (utn[0].medium === "paid" || utn[0].medium === "cpc")) ||
        (utn[utn.length - 1].source === "facebook" && (utn[utn.length - 1].medium === "paid" || utn[utn.length - 1].medium === "cpc")) ||
        (utn[0].source === "tiktok" && (utn[0].medium === "paid" || utn[0].medium === "cpc")) ||
        (utn[utn.length - 1].source === "tiktok" && (utn[utn.length - 1].medium === "paid" || utn[utn.length - 1].medium === "cpc"))
      ) && 
      !utn.find(track => (track.source === "nucamp"))
    ) return true;
    else return false;
  }

  public parseQueryString(queryString) {
    if (queryString) {
      const query:any = {};
      const qMarkIndex = queryString.indexOf('?');
      
      const pairs = (qMarkIndex !== -1 ? queryString.substring(qMarkIndex + 1) : queryString).split('&');
      for (let i = 0; i < pairs.length; i++) {
          const pair = pairs[i].split('=');
          query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
      }
      return query;
    } else {
      return {} as any;
    }
  } 

  public getUTN(){
    try {
      if (this.nativeWindow.localStorage) {
        const UTN = localStorage.getItem('utn');
        if (UTN) {
          const arrUTN = JSON.parse(UTN);
          if (arrUTN.length && arrUTN.length > 0)
            return arrUTN;
          else return null;
        }
          else return null;
      } else return null;
    } catch (err) {
      return null;
    }
  }

  public getCommunity(){
    if (this.nativeWindow.localStorage) {
      const com = localStorage.getItem('community');
      if (com) return JSON.parse(com)
        else return null;
    } else return null;
  }

  public detectIE() {
    const ua = this.nativeWindow.navigator.userAgent;
  
    // Test values; Uncomment to check result …
  
    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
  
    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
  
    // IE 12 / Spartan
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
  
    // Edge (IE 12+)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';
  
    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }
  
    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }
  
    // other browser
    return false;
  }

  public setCommunity(name, state){
    if (this.nativeWindow.localStorage) {
      // localStorage supported
      const myObj = { name: urlFix(name) , state: state.toLowerCase()};
      // TX and PA hack
      // if (state === "TX") myObj = { name: "online-central" , state: "on"};
      // if (state === "PA") myObj = { name: "online-eastern" , state: "on"};
      this.nativeWindow.localStorage.setItem('community', JSON.stringify(myObj));
      return myObj;
    }
    else return null
  }

  public applyAmbassadorCoupon() {
    const source = this.queryParamMap("utm_source");
    const ambassador = this.queryParamMap("utm_medium");
    if (source === "ambassador" && ambassador) {
      this.setCoupon(`${source}-${ambassador}`);
    }
  }

  public getReferral(){
    this.referral = this.queryParamMap("referral");
    this.setCoupon(this.queryParamMap("coupon"));
  }

  public setCoupon(coupon) {
    if (coupon) this.coupon = coupon.toUpperCase();
  }

  public getCommunityGoogleUrl(){
    return this.queryParamMap("location")
  }

  public getCommunityJSONUrl(){
    const bdid = this.queryParamMap("bdid");
    const name = this.queryParamMap("cn");
    const state = this.queryParamMap("cs");
    return {bdid: bdid.toUpperCase(), name: urlFix(name), state: state.toLowerCase()}
  }

  public get nativeDocument() {
    return this.document;
  }

  public get zE() {
    return _window().zE;
  }

  public get crisp() {
    if (isPlatformBrowser(this.platformId)) {
      return _crisp();
    } else {
      return [];
    }
  }
  
  public get nativeWindow() {
    if (isPlatformBrowser(this.platformId)) {
      return _window();
    } else if (isPlatformServer(this.platformId)) {
      return this.document.defaultView;
    }
  }

  public userAgent() {
    if (this.nativeWindow?.navigator?.userAgent) {
      return this.nativeWindow.navigator.userAgent;
    } else {
      return "";
    }
  }

  public isBot() {
    const bots = [
    "adsbot-google",
    "adsbot-google-mobile",
    "adidxbot",
    "amznkassocBot",
    "mediapartners-google",
    "google web preview",
    "google-adwords-displayads",
    "google-adwords-displayads-webrender",
    "google-structured-data-testing-tool",
    "googlebot",
    "chrome-lighthouse",
    "bingbot",
    "yandexbot",
    "duckduckbot",
    "slurp",
    "yahoo",
    "baiduspider",
    "facebookexternalhit",
    "twitterbot",
    "rogerbot",
    "linkedinbot",
    "embedly",
    "quora link preview",
    "showyoubot",
    "outbrain",
    "pinterest",
    "slackbot",
    "vkShare",
    "W3C_Validator",
    "redditbot",
    "Applebot",
    "WhatsApp",
    "flipboard",
    "tumblr",
    "bitlybot",
    "skypeuripreview",
    "nuzzel",
    "discordbot",
    "qwantify",
    "pinterestbot",
    "bitrix link preview",
    "xing-contenttabreceiver",
    "semrushbot",
    "semrushbot-sa",
    "rogerbot",
    "scremaing",
    "headlesschrome",
    "headless",
  ];
    const userAgent = this.userAgent();
    const isBot = bots.find((bot) => {
      // console.log(bot.toLowerCase());
      // console.log(userAgent.toLowerCase());
      return userAgent.toLowerCase().includes(bot.toLowerCase());
    });
    // console.log("isBot", isBot);
    // console.log("userAgent", userAgent);
    return isBot;
  }

  public get nativeLocation() {
    const serverRenderedLocation = {
      host: 'www.nucamp.co',
      hostname: 'www.nucamp.co',
      protocol: 'https:',
      port: '80',
      origin: 'https://www.nucamp.co',
      href: 'https://www.nucamp.co' + this.router.url.split("?")[0],
      pathname: this.router.url.split("?")[0],
      search: this.router.url.split("?")[1] ? "?" + this.router.url.split("?")[1] : "",
    }
    // console.log("this.document.location.host", this.document.location.host);
    // console.log("JSON Activated Route Params", JSON.stringify(this.activatedRoute.snapshot.params))
    if (!this.document.location.host || (this.document.location.host && !(this.document.location.host.includes('nucamp.co') || this.document.location.host.includes('nucamp-12c45.firebaseapp.com') || this.document.location.host.includes('nucamp-production.firebaseapp.com') || this.document.location.host.includes('localhost')))) {
      // If the page is rendered on the server
      // console.log("shim Document Location")
      return serverRenderedLocation;
    }
    return this.document.location;
  }
  
  public getQueryStringValue (key, inputString) {
    return decodeURIComponent(inputString.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
  } 

  public queryParamMap(queryParam) {
    const query = this.activatedRoute.snapshot.queryParamMap.get(queryParam);
    // console.log(`queryParam ${queryParam}`, query);
    return query || '';

  }

  public sendEmail(maildata) {
    this.nativeWindow.open(maildata, '_self'); 
  }

  public openTab(url) {
    return this.nativeWindow.open(url, '_blank');
  }

  public openModalWindow(inputurl, nopopup?) {
    let url = inputurl;
    if (!nopopup) url = url + "&popup=true";
    const window = this.nativeWindow;
    const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
    const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;
    const width = window.innerWidth ? window.innerWidth : window.document.documentElement.clientWidth ? window.document.documentElement.clientWidth : window.screen.width;
    const height = window.innerHeight ? window.innerHeight : window.document.documentElement.clientHeight ? window.document.documentElement.clientHeight : window.screen.height;
    const left = ((width / 2) - (1200 / 2)) + dualScreenLeft;
    const top = ((height / 2) - (700 / 2)) + dualScreenTop;
    const newWindow = window.open(url, 'title', 'scrollbars=yes, width=' + 1200 + ', height=' + 700 + ', top=' + top + ', left=' + left);
    if (window.focus) {
      newWindow.focus();
    }
  }
}
