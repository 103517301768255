import { Injectable } from '@angular/core';
import { Crisp } from "crisp-sdk-web";
import { environment } from 'src/environments/environment';

Crisp.configure(environment.crispChatWebsiteID);

@Injectable({ providedIn: 'root' })
export class ChatService {

  constructor(
  ) {   }

  identifyUser(user) {
    try {
      const data:any = {
        website: "www.nucamp.co"
      };
      if (user) {
        if (user?.uid) data.UID = user.uid;
        if (user?.email) Crisp.user.setEmail(user.email);
        if (user?.displayName) Crisp.user.setNickname(user.displayName);
      }
      if (user?.country) data.country = user.country;
      if (user?.state) data.state = user.state;
      // console.log("identifyUser", data);
      Crisp.session.setData(data);
    } catch (error) {
      console.log("Error while seeting Crisp data", user);
    }
  }

  show() {
    Crisp.chat.show();
  }

  hide() {
    Crisp.chat.hide();
  }

  welcomeBack() {
    Crisp.chat.show();
    Crisp.message.show("text", "Welcome Back! How can I help?");
  }
}
