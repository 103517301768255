import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { MenuController, Platform, IonicModule } from "@ionic/angular";
import { WindowrefService } from "./services/windowref/windowref.service";
import { CommunityService } from "./services/community/community.service";
import { NavigateService } from "./services/navigate/navigate.service";
import { UsersService } from "./services/users/users.service";
import { openJobsWindow, urlFix } from "./shared/routines";
import { BrowseCommunitiesPage } from "./pages/browse-communities/browse-communities.page";
import { environment } from "../environments/environment";
import { ActivationEnd, Router, RouterLink } from "@angular/router";
import { NgcCookieConsentService, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from "ngx-cookieconsent";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { NucampanalyticsService } from "./services/nucampanalytics/nucampanalytics.service";
import { ChatService } from "./services/chat/chat.service";
import { NgIf, NgTemplateOutlet } from "@angular/common";

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"],
    standalone: true,
    imports: [IonicModule, NgIf, NgTemplateOutlet, RouterLink]
})
export class AppComponent implements OnInit, OnDestroy {
  showBootcampMenu: boolean;
  showMoreMenu: boolean;
  showAdminMenu: boolean;
  showFinancingMenu: boolean;
  showCareerServicesMenu: boolean;
  showBusinessMenu: boolean;
  currentCommunity;
  environment = environment;
  isShowingRouteLoadIndicator: boolean;
  cannotSeeCompleteSE = false;
  gtmAddedToDom = false;
  appInitialized = false;
  trackingLoaded = false

  //keep refs to subscriptions to be able to unsubscribe later
  subArray = [];

  constructor(
    private platform: Platform,
    public windowRef: WindowrefService,
    private comProvider: CommunityService,
    public userProvider: UsersService,
    private nucampnav: NavigateService,
    private menu: MenuController,
    private nucampAnalytics: NucampanalyticsService,
    private chatService: ChatService,
    private router: Router,
    private gtm: GoogleTagManagerService,
    private inj: Injector
  ) { 
    this.isShowingRouteLoadIndicator = false;

		// As the router loads modules asynchronously (via loadChildren), we're going to
		// keep track of how many asynchronous requests are currently active. If there is
		// at least one pending load request, we'll show the indicator.
		// let asyncLoadCount = 0;
 
		// The Router emits special events for "loadChildren" configuration loading. We
		// just need to listen for the Start and End events in order to determine if we
		// have any pending configuration requests.
		this.subArray.push(this.router.events.subscribe(
			( event ) : void => {
        if ( event instanceof ActivationEnd ) {
          // console.log("event.snapshot.url", event.snapshot.url);
          if (event.snapshot.url.find(p => p.path === "admin" || p.path === "instructor")) {
            this.windowRef.hideChatWidget(true);
          } else {
            this.windowRef.showChatWidget(true);
          }
          if (!this.trackingLoaded) {
            this.windowRef.getReferral();
            this.windowRef.applyAmbassadorCoupon();
            this.windowRef.utnTracking();
            this.windowRef.setFirstVisit();
            this.trackingLoaded = true;
          }
        }
			}
		));
  }

  async ngOnInit() {
    await this.nucampAnalytics.getGeoLocation();

    const showConsentTimezones = {
      from: -60*4,
      to: 60*1
    }
    const utcOffset = new Date().getTimezoneOffset();
    // console.log("showConsentTimezones", showConsentTimezones);
    // console.log("utcOffset", utcOffset);

    if (utcOffset >= showConsentTimezones.from && utcOffset <= showConsentTimezones.to) {
      const ccService = this.inj.get(NgcCookieConsentService);
      // console.log("environment.enableGRDP", environment.enableGRDP);
      if (!environment.enableGRDP && !this.windowRef.isBot()) {
        setTimeout(() => {
          this.gtm.addGtmToDom()
          .then(() => {
            this.nucampAnalytics.addGTMtoDOM();
            this.gtmAddedToDom = true;
          })
          .catch((res) => {});
        }, 3000)
      }

      // subscribe to cookieconsent observables to react to main events
      this.subArray.push(ccService.popupOpen$.subscribe(() => {
          // you can use this.ccService.getConfig() to do stuff...
        }));
    
      this.subArray.push(ccService.popupClose$.subscribe(() => {
        // you can use this.ccService.getConfig() to do stuff...
      }));
    
      this.subArray.push(ccService.initializing$.subscribe((event: NgcInitializingEvent) => {
        console.log("initializing cookies consent");
      }));
    
      this.subArray.push(ccService.statusChange$.subscribe(async (event: NgcStatusChangeEvent) => {
          console.log("cookies consent event", event);
          if (event.status !== "deny" && !this.gtmAddedToDom && !this.windowRef.isBot()) {
            // console.log("enabling cookies");
            setTimeout(() => {
              this.gtm.addGtmToDom()
              .then(() => {
                this.nucampAnalytics.addGTMtoDOM();
                this.gtmAddedToDom = true;
              })
              .catch((res) => {});
            }, 3000)
          }
      }));
    
      this.subArray.push(ccService.revokeChoice$.subscribe(() => {
          // you can use this.ccService.getConfig() to do stuff...
        }));
    
      this.subArray.push(ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }));
      return this.initializeApp();
    } else {
      return this.initializeApp().then(() => {
        if (!this.windowRef.isBot()) {
          this.chatService.show();
          const urlParams = new URLSearchParams(this.windowRef.nativeLocation.search);
          const showCrispChat = urlParams.get("showCrispChat");
          if (showCrispChat === "true") {
            this.chatService.welcomeBack();
          }
          setTimeout(() => {
            return this.gtm.addGtmToDom()
            .then(() => {
              this.nucampAnalytics.addGTMtoDOM();
              this.gtmAddedToDom = true;
            })
            .catch((res) => {
              // console.error("Error adding cookies", res);
            })
          }, 3000)
        } else {
          return Promise.resolve();
        }
      })
    }
  }

  initNucamp () {
    // console.log("initNucamp");
    this.appInitialized = true;
    // this.config.getConfig();

    return this.comProvider.readandsetCommunity()
    .then(() => {
      // this.sessionRec.randomRecording();
      this.comProvider.obsCommunity.subscribe(com => {
        // console.log("this.comProvider.obsCommunity.subscribe");
        this.currentCommunity = com;
        // this.comProvider.cannotSeeCompleteSE(com).then(result => this.cannotSeeCompleteSE = result);
      });
      // this.notifications.showNotifications(null);
      setTimeout(() => {
        try { 
          // this.windowRef.repositionZendesk();
          this.windowRef.deleteSlowNetworkLoader();
        }
        catch (err) { }
      }, 2000);
    });
  }

  initializeApp() {
    return this.platform.ready().then(() => {
      this.windowRef.disableConsoleLog();
      if (!this.appInitialized) this.initNucamp();
    })

  }

  openPage(page) {
    this.nucampnav.openPage(page)
      .then(() => this.closeMenu())
  }

  openJobsWindow() {
    this.closeMenu()
    openJobsWindow(this.windowRef)
  }

  openBlog() {
    this.nucampnav.openPage("blogs")
      .then(() => this.closeMenu())
  }

  openBusiness() {
    this.showBusinessMenu = !this.showBusinessMenu;
    this.showMoreMenu = false;
    this.showAdminMenu = false;
    this.showFinancingMenu = false;
    this.showCareerServicesMenu = false;
  }

  openCommunity(location?, state?) {
    this.closeMenu()
    if (!state && !location) {
      this.comProvider.getCurrentCommunity()
        .then(com => {
          if (com) {
            this.nucampnav.openPage(`community/${com.state.toLowerCase()}/${urlFix(com.name)}`);
          }
          else {
            this.nucampnav.openModal(BrowseCommunitiesPage, null, { event: "Browse Communities", animate: true, direction: "forward" }).then(modal => modal.present());
          }
        })
    }
    else if (location) {
      if (!state) state = "wa";
      this.nucampnav.openPage(`community/${state.toLowerCase()}/${urlFix(location)}`);
    }
  }

  openBootcamps() {
    this.showBootcampMenu = !this.showBootcampMenu;
    this.showMoreMenu = false;
    this.showAdminMenu = false;
    this.showFinancingMenu = false;
    this.showCareerServicesMenu = false;
    this.showBusinessMenu = false;
  }

  openMore() {
    this.showBootcampMenu = false;
    this.showFinancingMenu = false;
    this.showMoreMenu = !this.showMoreMenu;
    this.showAdminMenu = false;
    this.showCareerServicesMenu = false;
    this.showBusinessMenu = false;
  }

  openAdmin() {
    this.showBootcampMenu = false;
    this.showMoreMenu = false;
    this.showFinancingMenu = false;
    this.showCareerServicesMenu = false;
    this.showBusinessMenu = false;
    this.showAdminMenu = !this.showAdminMenu;
  }

  openFinancing() {
    this.showBootcampMenu = false;
    this.showMoreMenu = false;
    this.showAdminMenu = false;
    this.showCareerServicesMenu = false;
    this.showBusinessMenu = false;
    this.showFinancingMenu = !this.showFinancingMenu;
  }

  openCareerServices() {
    this.showBootcampMenu = false;
    this.showMoreMenu = false;
    this.showAdminMenu = false;
    this.showFinancingMenu = false;
    this.showBusinessMenu = false;
    this.showCareerServicesMenu = !this.showCareerServicesMenu;
  }

  closeMenu() {
    this.menu.close("hamburger-menu");
    this.showBootcampMenu = false;
    this.showMoreMenu = false;
    this.showBusinessMenu = false;
    this.showAdminMenu = false;
    this.showCareerServicesMenu = false;
    this.showFinancingMenu = false;
    this.showBusinessMenu = false;
  }

  toggleMenu() {
    this.menu.close("hamburger-menu");
    this.showBootcampMenu = false;
    this.showMoreMenu = false;
    this.showBusinessMenu = false;
    this.showAdminMenu = false;
    this.showCareerServicesMenu = false;
    this.showFinancingMenu = false;
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.subArray.forEach((sub) => sub.unsubscribe())
  }
}
